import { Links, Meta, Scripts, useRouteError } from '@remix-run/react';

export function ErrorBoundaryRootPayment() {
  const error = useRouteError();
  console.error(error);
  const errorId = new Date().getTime();

  return (
    <html lang="en" className="h-full">
      <head>
        <title>Unexpected Error</title>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <main className="relative isolate min-h-full">
          <img
            src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
            <p className="text-base font-semibold leading-8 text-white">
              Error
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
              We've encountered an error
            </h1>
            <p className="mt-4 text-base text-white/70 sm:mt-6">
              We're very sorry for the inconvenience. Here's an error code you
              can use to help us solve this issue:{' '}
              <strong>{(errorId && errorId) || ''}</strong>
            </p>
            <div className="mt-10 flex justify-center space-x-4">
              <a
                href={`mailto:support@example.com?subject=Error%20Report&body=Error%20ID:%20${(errorId && errorId) || ''}`}
                className="text-sm font-semibold leading-7 text-white"
              >
                Report this error
              </a>
              <button
                onClick={() => window.history.back()}
                className="text-sm font-semibold leading-7 text-white"
              >
                Go back
              </button>
            </div>
          </div>
        </main>
        <Scripts />
      </body>
    </html>
  );
}
