// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { initCoralogix, logInfo } from '@entrycall/coralogix';
import twStyles from './styles/tailwind.css';
import { Provider } from 'react-redux';
import { store } from '@entrycall/redux';
import { sanitizeHTML, sharedDOMPurify } from '@entrycall/utility-dompurify';
import { LiveReload, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { paymentRootLoader } from '@entrycall/parking-payment-remix-server';
import { useEffect, useState, useMemo } from 'react';
import { AuthProvider } from '@descope/react-sdk';
import { ErrorBoundaryRootPayment, EntryCallHtmlDocument, metaData } from '@entrycall/lib-remix-root-configuration';
export const links = () => [{
  rel: 'stylesheet',
  href: twStyles
}];
export const loader = paymentRootLoader;
export const meta = metaData;
export const ErrorBoundary = ErrorBoundaryRootPayment;

// Create or get a global TrustedHTML Policy
const getTrustedHTMLPolicy = () => {
  if (typeof window !== 'undefined' && window.trustedTypes) {
    try {
      // Check if the default policy already exists
      if (window.trustedTypes.defaultPolicy) {
        return window.trustedTypes.defaultPolicy;
      }

      // If it doesn't exist, create a new one
      return window.trustedTypes.createPolicy('default', {
        // createHTML: (input: string) => DOMPurify.sanitize(input),
        createHTML: input => sharedDOMPurify.sanitize(input),
        createScript: input => input,
        createScriptURL: input => input
      });
    } catch (error) {
      console.warn('Trusted Types policy creation failed:', error);
    }
  }
  return undefined;
};
const DESCOPE_PROJECT_ID = 'P2gbxFS9y61jkSPQ8us5qtixoBoL';
export default function App() {
  _s();
  const loaderData = useLoaderData();
  const envData = loaderData?.ENV ?? {};
  const sanitizedEnvScript = useMemo(() => sanitizeHTML(`window.ENV = ${JSON.stringify(envData)}`), [envData]);
  const [trustedHTML, setTrustedHTML] = useState(sanitizedEnvScript);
  useEffect(() => {
    const trustedHTMLPolicy = getTrustedHTMLPolicy();
    if (trustedHTMLPolicy) {
      setTrustedHTML(trustedHTMLPolicy.createHTML(sanitizedEnvScript));
    } else {
      setTrustedHTML(sanitizedEnvScript);
    }
  }, [sanitizedEnvScript]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const initApp = async () => {
        await initCoralogix();
        await logInfo('Application initialized', {
          env: envData
        });
      };
      initApp();
    }
  }, []);
  return <EntryCallHtmlDocument>
      <Provider store={store}>
        <AuthProvider projectId={DESCOPE_PROJECT_ID}>
          <Outlet />
        </AuthProvider>
        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{
        __html: trustedHTML
      }} />

        <Scripts />
        <LiveReload />
      </Provider>
    </EntryCallHtmlDocument>;
}
_s(App, "MC27r2lbpx6whuiutttii3A2Ck0=", false, function () {
  return [useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;