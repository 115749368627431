import { type LoaderFunction, json } from '@remix-run/node';

/**
 * Loader function for the root route.
 *
 * @returns {Promise<Response>} A JSON response containing the environment variables.
 */
export const paymentRootLoader: LoaderFunction = async () => {
  return json({
    ENV: {
      STRIPE_PUBLIC_KEY: process.env.NX_STRIPE_PUBLISHABLE_KEY,
    },
  });
};
