let coralogixPromise: Promise<any>;
let initializationPromise: Promise<void> | null = null;
let isInitialized = false;

if (typeof window !== 'undefined') {
  // Client-side code
  coralogixPromise = import('@coralogix/browser').then(
    (module) => module.CoralogixRum,
  );
} else {
  // Server-side code
  coralogixPromise = Promise.resolve(null);
}

export const initCoralogix = async () => {
  if (initializationPromise) {
    return initializationPromise;
  }

  initializationPromise = new Promise(async (resolve) => {
    if (isInitialized) {
      console.log('Coralogix RUM already initialized');
      resolve();
      return;
    }

    try {
      const CoralogixRum = await coralogixPromise;
      if (CoralogixRum) {
        CoralogixRum.init({
          public_key: 'cxtp_VA7Kh6c1gxZm8i4aTw32RRHY7ynMUF',
          application: 'entrycall-portal',
          version: '2.0.1',
          coralogixDomain: 'EU1',
        });
        isInitialized = true;
        console.log('Coralogix RUM initialized successfully');
      }
    } catch (error) {
      console.error('Failed to initialize Coralogix:', error);
    }
    resolve();
  });

  return initializationPromise;
};

export const logDebug = async (message: string, context?: object) => {
  const CoralogixRum = await coralogixPromise;
  if (CoralogixRum && typeof CoralogixRum.debug === 'function') {
    CoralogixRum.debug(message, context);
  }
};

export const logInfo = async (message: string, context?: object) => {
  const CoralogixRum = await coralogixPromise;
  if (CoralogixRum && typeof CoralogixRum.info === 'function') {
    CoralogixRum.info(message, context);
  }
};

export const logWarn = async (message: string, context?: object) => {
  const CoralogixRum = await coralogixPromise;
  if (CoralogixRum && typeof CoralogixRum.warn === 'function') {
    CoralogixRum.warn(message, context);
  }
};

export const logError = async (message: string, context?: object) => {
  const CoralogixRum = await coralogixPromise;
  if (CoralogixRum && typeof CoralogixRum.error === 'function') {
    CoralogixRum.error(message, context);
  }
};

export const logCritical = async (message: string, context?: object) => {
  const CoralogixRum = await coralogixPromise;
  if (CoralogixRum && typeof CoralogixRum.critical === 'function') {
    CoralogixRum.critical(message, context);
  }
};

export const safeLogInfo = async (message: string, context?: object) => {
  try {
    const CoralogixRum = await coralogixPromise;
    if (CoralogixRum && typeof CoralogixRum.info === 'function') {
      CoralogixRum.info(message, context);
    }
  } catch (error) {
    console.error('Failed to send log to Coralogix:', error);
  }
};
